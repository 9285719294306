<template>
  <q-form ref="editForm">
    <c-card title="요청상세" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="preStartupCheckItem"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="savePreStartupCheckItem"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            class="q-pb-sm"
            type="edit" 
            name="plantCd" 
            v-model="preStartupCheckItem.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :required="true" 
            :disabled="disabled"
            :editable="editable"
            class="q-pb-sm"
            codeGroupCd="PSR_CHECK_TYPE_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="psrCheckTypeCd"
            label="점검유형"
            v-model="preStartupCheckItem.psrCheckTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text 
            v-show="showLevel1"
            class="q-pb-sm"
            :disabled="true"
            :editable="editable"
            label="대분류"
            name="first"
            v-model="first">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text 
            v-show="showLevel2"
            class="q-pb-sm"
            :disabled="true"
            :editable="editable"
            label="중분류"
            name="second"
            v-model="second">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text 
            :required="true" 
            :editable="editable"
            label="항목명"
            name="sopPrestartupCheckItemName"
            v-model="preStartupCheckItem.sopPrestartupCheckItemName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-radio
            :editable="editable"
            :comboItems="useFlagItems"
            label="사용여부"
            name="useFlag"
            v-model="preStartupCheckItem.useFlag">
          </c-radio>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text 
            :editable="editable"
            label="순번"
            name="sortOrder"
            v-model="preStartupCheckItem.sortOrder">
          </c-text>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'pre-startup-review-item-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopPrestartupCheckItemId: '',
        psrDataLevelCd: '',
        level: {
          psrCheckTypeCd: '',
          first: '',
          second: '',
        }
      }),
    },
  },
  data() {
    return {
      preStartupCheckItem: {
        sopPrestartupCheckItemId: '',  // 점검항목번호
        sopPrestartupCheckItemName: '',  // 점검항목명
        plantCd: null,  // 사업장코드
        psrCheckTypeCd: null,  // 점검유형
        psrDataLevelCd: null,  // 데이터 레벨
        psopPrestartupCheckItemId: '',  // 상위 점검항목
        useFlag: 'Y',  // 사용여부
        sortOrder: 0,  // 순번
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      first: '',
      second: '',
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      showLevel1: false,
      showLevel2: false,
      editable: true,
      listUrl: '',
      detailUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'POST'
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.popupParam.sopPrestartupCheckItemId || this.showLevel1 || this.showLevel2 ? true : false;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.psr.checkItem.list.url;
      this.detailUrl = selectConfig.sop.psr.checkItem.get.url;
      this.saveUrl = transactionConfig.sop.psr.checkItem.insert.url
      // code setting
      // list setting
      this.getPreStartupCheckItem();
    },
    getPreStartupCheckItem() {
      /**
       * 데이터 레벨에 따라 표시하는 영역을 따로 표기
       * 
       *  # 대분류 : showLevel1, showLevel2 false & 대분류, 중분류 리스트 조회 X
       *  # 중분류 : showLevel1 true, showLevel2 false & 대분류 리스트 조회, 중분류 리스트 조회 X
       *  # 대분류 : showLevel1, showLevel2 true & 대분류, 중분류 리스트 조회
       */
      if (!this.popupParam.level.first) {
        this.showLevel1 = false
        this.showLevel2 = false
      } else if (!this.popupParam.level.second) {
        this.showLevel1 = true;
        this.showLevel2 = false;
        this.getLevelItems(this.popupParam.level.first, 1)
      } else {
        this.showLevel1 = true
        this.showLevel2 = true
        this.getLevelItems(this.popupParam.level.first, 1)
        this.getLevelItems(this.popupParam.level.second, 2)
      }

      /**
       * 데이터 조회
       */
      if (this.popupParam.sopPrestartupCheckItemId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopPrestartupCheckItemId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.preStartupCheckItem, _result.data);
        },);
      } else {
        this.preStartupCheckItem.psrCheckTypeCd = this.popupParam.level.psrCheckTypeCd
        this.preStartupCheckItem.psopPrestartupCheckItemId = this.popupParam.level.second ? this.popupParam.level.second : this.popupParam.level.first
        
        this.$comm.getComboItems('PSR_DATA_LEVEL_CD').then(_result => {
          this.preStartupCheckItem.psrDataLevelCd = this.popupParam.level.second ? _result[2].code : this.popupParam.level.first ? _result[1].code : _result[0].code
        });
      }
    },
    getLevelItems(psopPrestartupCheckItemId, level) {
      this.$http.url = this.$format(this.detailUrl, psopPrestartupCheckItemId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (level === 1) {
          // 대분류
          this.first = _result.data.sopPrestartupCheckItemName
        } else {
          // 중분류
          this.second = _result.data.sopPrestartupCheckItemName
        }
      },);
    },
    savePreStartupCheckItem() {
      if (this.popupParam.sopPrestartupCheckItemId) {
        this.saveUrl = transactionConfig.sop.psr.checkItem.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.psr.checkItem.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.preStartupCheckItem.regUserId = this.$store.getters.user.userId
              this.preStartupCheckItem.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.sopPrestartupCheckItemId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getPreStartupCheckItem();
    },
  }
};
</script>
